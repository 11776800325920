<template>
  <div>
    <div class="tabs">
      <ul v-sticky sticky-offset="offset">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.products' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Προιόντα
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.brands' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Brands
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.collections' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Collections
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.symptoms' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Συμπτώματα
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.orders' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Παραγγελίες
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.customers' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Πελάτες
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.content' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Περιεχόμενο
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.settings' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Ρυθμίσεις
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'logs.users' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Χρήστες
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
