var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs"},[_c('ul',{directives:[{name:"sticky",rawName:"v-sticky"}],attrs:{"sticky-offset":"offset"}},[_c('router-link',{attrs:{"to":{ name: 'logs.products' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Προιόντα ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.brands' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Brands ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.collections' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Collections ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.symptoms' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Συμπτώματα ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.orders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Παραγγελίες ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.customers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Πελάτες ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.content' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Περιεχόμενο ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.settings' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Ρυθμίσεις ")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'logs.users' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Χρήστες ")])])]}}])})],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }